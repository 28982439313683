//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { preFetchPage } from '@/helpers/router';
export default {
  name: 'Venue',
  preFetch: preFetchPage,
  meta: function meta() {
    var _this$seoData, _this$seoData2, _this$seoData3;

    return {
      title: ((_this$seoData = this.seoData) === null || _this$seoData === void 0 ? void 0 : _this$seoData.seoTitle) || 'PiasterCreek',
      meta: {
        description: {
          name: 'description',
          content: (_this$seoData2 = this.seoData) === null || _this$seoData2 === void 0 ? void 0 : _this$seoData2.seoDescription
        },
        keywords: {
          name: 'keywords',
          content: ((_this$seoData3 = this.seoData) === null || _this$seoData3 === void 0 ? void 0 : _this$seoData3.seoKeywords) || ''
        }
      }
    };
  }
};